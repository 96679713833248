"use strict";

exports.__esModule = true;
var AddressingModes;

(function (AddressingModes) {
  AddressingModes[AddressingModes["Absolute"] = 0] = "Absolute";
  AddressingModes[AddressingModes["Accumulator"] = 1] = "Accumulator";
  AddressingModes[AddressingModes["Immediate"] = 2] = "Immediate";
  AddressingModes[AddressingModes["Implied"] = 3] = "Implied";
  AddressingModes[AddressingModes["Indirect"] = 4] = "Indirect";
  AddressingModes[AddressingModes["Relative"] = 5] = "Relative";
  AddressingModes[AddressingModes["ZeroPage"] = 6] = "ZeroPage";
  AddressingModes[AddressingModes["AbsoluteX"] = 7] = "AbsoluteX";
  AddressingModes[AddressingModes["AbsoluteY"] = 8] = "AbsoluteY";
  AddressingModes[AddressingModes["IndexedIndirect"] = 9] = "IndexedIndirect";
  AddressingModes[AddressingModes["IndirectIndexed"] = 10] = "IndirectIndexed";
  AddressingModes[AddressingModes["ZeroPageX"] = 11] = "ZeroPageX";
  AddressingModes[AddressingModes["ZeroPageY"] = 12] = "ZeroPageY";
})(AddressingModes = exports.AddressingModes || (exports.AddressingModes = {}));

exports.emptyFlags = function () {
  return {
    C: 0,
    Z: 0,
    I: 0,
    D: 0,
    B: 0,
    V: 0,
    N: 0
  };
};