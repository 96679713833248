"use strict";

exports.__esModule = true;
var STARTING_ADDRESS = 0x0000;

var writeToRam = function writeToRam(bytes, ram, offset) {
  if (offset === void 0) {
    offset = 0x0;
  }

  return ram.set(bytes, STARTING_ADDRESS + offset), ram;
};

exports.createVM = function (onVmUpdate, program) {
  var ram = Uint8Array.from(new Array(1024 * 64));
  var registers = {
    PC: STARTING_ADDRESS,
    SP: 0xff,
    A: 0,
    X: 0,
    Y: 0
  }; // http://www.obelisk.me.uk/6502/registers.html

  var flags = {
    C: 0,
    Z: 0,
    I: 0,
    D: 0,
    B: 0,
    V: 0,
    N: 0
  };
  return {
    ram: program ? writeToRam(program, ram) : ram,
    flags: flags,
    registers: registers,
    onVmUpdate: onVmUpdate
  };
};