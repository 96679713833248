"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

exports.__esModule = true;

var types_1 = require("../types");

var load_1 = require("./load");

var store_1 = require("./store");

var compare_1 = require("./compare"); // Reference https://www.masswerk.at/6502/6502_instruction_set.html


exports.instructions = function () {
  return __assign({}, load_1.load(), compare_1.compare(), store_1.store(), {
    0x4c: {
      mnemonic: "JMP",
      handler: function handler() {},
      addressingMode: types_1.AddressingModes.Absolute
    },
    0x6c: {
      mnemonic: "JMP",
      handler: function handler() {},
      addressingMode: types_1.AddressingModes.Indirect
    },
    0x20: {
      mnemonic: "JSR",
      handler: function handler() {},
      addressingMode: types_1.AddressingModes.Absolute
    },
    0x40: {
      mnemonic: "RTI",
      handler: function handler() {},
      addressingMode: types_1.AddressingModes.Implied
    },
    0x60: {
      mnemonic: "RTS",
      handler: function handler() {},
      addressingMode: types_1.AddressingModes.Implied
    }
  });
};