"use strict";

exports.__esModule = true;

var types_1 = require("../types");

var handleByteFromMemory_1 = require("../util/handleByteFromMemory");

var compareHandler = function compareHandler(reg) {
  return function (vm, M) {
    vm.flags.Z = vm.registers.A === M ? 1 : 0;
    vm.flags.C = vm.registers.A >= M ? 1 : 0;
  };
};

exports.cmpHandler = function () {
  return compareHandler("A");
};

exports.cpyHandler = function () {
  return compareHandler("Y");
};

exports.cpxHandler = function () {
  return compareHandler("X");
};

exports.compare = function () {
  return {
    0xc9: {
      mnemonic: "CMP",
      handler: exports.cmpHandler,
      addressingMode: types_1.AddressingModes.Immediate
    },
    0xcd: {
      mnemonic: "CMP",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.cmpHandler),
      addressingMode: types_1.AddressingModes.Absolute
    },
    0xdd: {
      mnemonic: "CMP",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.cmpHandler),
      addressingMode: types_1.AddressingModes.AbsoluteX
    },
    0xd9: {
      mnemonic: "CMP",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.cmpHandler),
      addressingMode: types_1.AddressingModes.AbsoluteY
    },
    0xc5: {
      mnemonic: "CMP",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.cmpHandler),
      addressingMode: types_1.AddressingModes.ZeroPage
    },
    0xd5: {
      mnemonic: "CMP",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.cmpHandler),
      addressingMode: types_1.AddressingModes.ZeroPageX
    },
    0xc1: {
      mnemonic: "CMP",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.cmpHandler),
      addressingMode: types_1.AddressingModes.IndexedIndirect
    },
    0xd1: {
      mnemonic: "CMP",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.cmpHandler),
      addressingMode: types_1.AddressingModes.IndirectIndexed
    },
    0xe0: {
      mnemonic: "CPX",
      handler: exports.cpxHandler,
      addressingMode: types_1.AddressingModes.Immediate
    },
    0xec: {
      mnemonic: "CPX",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.cpxHandler),
      addressingMode: types_1.AddressingModes.Absolute
    },
    0xe4: {
      mnemonic: "CPX",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.cpxHandler),
      addressingMode: types_1.AddressingModes.ZeroPage
    },
    0xc0: {
      mnemonic: "CPY",
      handler: exports.cpyHandler,
      addressingMode: types_1.AddressingModes.Immediate
    },
    0xcc: {
      mnemonic: "CPY",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.cpyHandler),
      addressingMode: types_1.AddressingModes.Absolute
    },
    0xc4: {
      mnemonic: "CPY",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.cpyHandler),
      addressingMode: types_1.AddressingModes.ZeroPage
    }
  };
};