import React from "react"
import { dec2Hex } from "@6502/emu/dist/util/bytes"
import { Registers } from "@6502/emu/dist/types"
import styled from "@emotion/styled"

interface Props {
  ram: Uint8Array
  registers: Registers
}

const HexDumpContainer = styled.pre`
  max-height: 300px;
  overflow-y: scroll;
  font-family: monaco, "andale mono", "lucida console", "courier new", monospace;
`

const MemoryAddress = styled.span`
  color: #aaa;
`

const MemoryByte = styled.span<{ isCurrentInstruction: boolean }>`
  background: ${props => (props.isCurrentInstruction ? "#F00" : "")};
`

export const RamDisplay: React.FC<Props> = ({ registers, ram }) => {
  return (
    <>
      <h3>Memory</h3>

      <HexDumpContainer>
        {[...ram].map((k, i) => (
          <span key={`memory-${k}-${i}`}>
            <MemoryAddress>
              {(i + 1) % 32 === 1 && `${dec2Hex(i).padStart(4, "0")} | `}
            </MemoryAddress>

            <span key={i}>
              <MemoryByte isCurrentInstruction={registers.PC === i}>
                {k.toString(16).padStart(2, "0") + " "}
              </MemoryByte>

              {!((i + 1) % 8) && " "}
              {!((i + 1) % 32) && <br />}
            </span>
          </span>
        ))}
      </HexDumpContainer>
    </>
  )
}
