import React from "react"
import { Flags } from "@6502/emu/dist/types"
import { Table } from "./Table"

interface Props {
  flags: Flags
}

export const FlagsDisplay: React.FC<Props> = ({ flags }) => (
  <>
    <h3>Status flags</h3>
    <Table items={flags} />
  </>
)
