import React from "react"
import { Registers } from "@6502/emu/dist/types"
import { Table } from "./Table"

interface Props {
  registers: Registers
}

export const RegistersDisplay: React.FC<Props> = ({ registers }) => (
  <>
    <h3>Registers</h3>
    <Table items={registers} />
  </>
)
