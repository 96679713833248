"use strict";

exports.__esModule = true;

var types_1 = require("../types");

var flags_1 = require("../util/flags");

var handleByteFromMemory_1 = require("../util/handleByteFromMemory");

exports.ldaHandler = function (vm, _byte) {
  vm.registers.A = _byte;
  flags_1.updateNZFlags(vm, vm.registers.A);
};

exports.ldxHandler = function (vm, _byte2) {
  vm.registers.X = _byte2;
  flags_1.updateNZFlags(vm, vm.registers.X);
};

exports.ldyHandler = function (vm, _byte3) {
  vm.registers.Y = _byte3;
  flags_1.updateNZFlags(vm, vm.registers.Y);
};

exports.load = function () {
  return {
    0xa9: {
      mnemonic: "LDA",
      handler: exports.ldaHandler,
      addressingMode: types_1.AddressingModes.Immediate
    },
    0xad: {
      mnemonic: "LDA",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.ldaHandler),
      addressingMode: types_1.AddressingModes.Absolute
    },
    0xbd: {
      mnemonic: "LDA",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.ldaHandler),
      addressingMode: types_1.AddressingModes.AbsoluteX
    },
    0xb9: {
      mnemonic: "LDA",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.ldaHandler),
      addressingMode: types_1.AddressingModes.AbsoluteY
    },
    0xa5: {
      mnemonic: "LDA",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.ldaHandler),
      addressingMode: types_1.AddressingModes.ZeroPage
    },
    0xb5: {
      mnemonic: "LDA",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.ldaHandler),
      addressingMode: types_1.AddressingModes.ZeroPageX
    },
    0xa1: {
      mnemonic: "LDA",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.ldaHandler),
      addressingMode: types_1.AddressingModes.IndexedIndirect
    },
    0xb1: {
      mnemonic: "LDA",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.ldaHandler),
      addressingMode: types_1.AddressingModes.IndirectIndexed
    },
    0xa2: {
      mnemonic: "LDX",
      handler: exports.ldxHandler,
      addressingMode: types_1.AddressingModes.Immediate
    },
    0xae: {
      mnemonic: "LDX",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.ldxHandler),
      addressingMode: types_1.AddressingModes.Absolute
    },
    0xbe: {
      mnemonic: "LDX",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.ldxHandler),
      addressingMode: types_1.AddressingModes.AbsoluteY
    },
    0xa6: {
      mnemonic: "LDX",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.ldxHandler),
      addressingMode: types_1.AddressingModes.ZeroPage
    },
    0xb6: {
      mnemonic: "LDX",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.ldxHandler),
      addressingMode: types_1.AddressingModes.ZeroPageY
    },
    0xa0: {
      mnemonic: "LDY",
      handler: exports.ldyHandler,
      addressingMode: types_1.AddressingModes.Immediate
    },
    0xac: {
      mnemonic: "LDY",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.ldyHandler),
      addressingMode: types_1.AddressingModes.Absolute
    },
    0xbc: {
      mnemonic: "LDY",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.ldyHandler),
      addressingMode: types_1.AddressingModes.AbsoluteX
    },
    0xa4: {
      mnemonic: "LDY",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.ldyHandler),
      addressingMode: types_1.AddressingModes.ZeroPage
    },
    0xb4: {
      mnemonic: "LDY",
      handler: handleByteFromMemory_1.handleByteFromMemory(exports.ldyHandler),
      addressingMode: types_1.AddressingModes.ZeroPageX
    }
  };
};