import React from "react"
import { Flags, Registers } from "@6502/emu/dist/types"
import styled from "@emotion/styled"

interface Props {
  items: Flags | Registers
}

const Th = styled.th`
  min-width: 40px;
  border: 1px solid #fff;
  padding: 2px;
`
const Td = styled.td`
  border: 1px solid #fff;
  padding: 2px;
`

const CenteredTable = styled.table`
  text-align: center;
`

export const Table: React.FC<Props> = ({ items }) => {
  return (
    <CenteredTable>
      <thead>
        <tr>
          {Object.keys(items).map(k => (
            <Th key={k}>{k}</Th>
          ))}
        </tr>
      </thead>

      <tbody>
        <tr>
          {Object.keys(items).map((k, i) => (
            <Td key={`items-${k}-${i}`}>
              <div>{items[k as keyof typeof items]}</div>
            </Td>
          ))}
        </tr>
      </tbody>
    </CenteredTable>
  )
}
