import React, { useRef, useState } from "react"
import { step, createVM } from "@6502/emu"
import { VM } from "@6502/emu/dist/types"
import { FlagsDisplay } from "./components/FlagsDisplay"
import { RegistersDisplay } from "./components/RegistersDisplay"
import { RamDisplay } from "./components/RamDisplay"
import { helloWorld } from "@6502/emu/dist/programs/hello-world"
import { Global, css } from "@emotion/core"

const App: React.FC = () => {
  const vmRef = useRef<VM | null>(null)
  const [vm, updateVm] = useState<VM>(createVM(() => {}))
  const _vm = createVM(updateVm, helloWorld)

  if (vmRef.current === null) {
    updateVm(_vm)
    vmRef.current = _vm
  }

  return (
    <>
      <Global
        styles={css`
          * {
            color: white;
            background-color: black;
            font-family: "Press Start 2P" !important;
            font-size: 14px;
          }
        `}
      />
      <RamDisplay ram={vm.ram.slice(0, 0x0700)} registers={vm.registers} />
      <button onClick={() => step(vm)}>Step</button>
      <FlagsDisplay flags={vm.flags} />
      <RegistersDisplay registers={vm.registers} />
    </>
  )
}

export default App
