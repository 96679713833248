"use strict";

var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
      r,
      ar = [],
      e;

  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) {
      ar.push(r.value);
    }
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }

  return ar;
};

var __spread = this && this.__spread || function () {
  for (var ar = [], i = 0; i < arguments.length; i++) {
    ar = ar.concat(__read(arguments[i]));
  }

  return ar;
};

exports.__esModule = true; // bytes is an typed array (Int8Array or Uint8Array)

exports.hex2Dec = function (hex) {
  return parseInt("0x" + hex);
};

exports.dec2Hex = function (_byte) {
  return (_byte < 16 ? "0" : "") + _byte.toString(16);
};

exports.bytesToAddress = function (bytes) {
  return exports.hex2Dec(__spread(bytes).reverse() // To deal with endianness
  .map(exports.dec2Hex).join(""));
};

exports.isBitSet = function (n, bit) {
  return (1 << bit & n) !== 0;
};