"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
      r,
      ar = [],
      e;

  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) {
      ar.push(r.value);
    }
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }

  return ar;
};

var __spread = this && this.__spread || function () {
  for (var ar = [], i = 0; i < arguments.length; i++) {
    ar = ar.concat(__read(arguments[i]));
  }

  return ar;
};

exports.__esModule = true;

var types_1 = require("./types");

var instructions_1 = require("./instructions");

var bytes_1 = require("./util/bytes");

var readNextAddress_1 = require("./util/readNextAddress");

exports.isMemoryInstruction = function (ins) {
  switch (ins.addressingMode) {
    case types_1.AddressingModes.Implied:
    case types_1.AddressingModes.Immediate:
    case types_1.AddressingModes.Accumulator:
      return false;

    case types_1.AddressingModes.Absolute:
    case types_1.AddressingModes.AbsoluteX:
    case types_1.AddressingModes.AbsoluteY:
    case types_1.AddressingModes.ZeroPage:
    case types_1.AddressingModes.ZeroPageX:
    case types_1.AddressingModes.ZeroPageY:
    case types_1.AddressingModes.Indirect:
      return true;
  }
};

exports.getArgumentForAddressingMode = function (vm, ins) {
  switch (ins.addressingMode) {
    // Non-memory
    case types_1.AddressingModes.Implied:
      return;

    case types_1.AddressingModes.Immediate:
      return vm.ram[vm.registers.PC++];

    case types_1.AddressingModes.Accumulator:
      return vm.registers.A;
    // Memory

    case types_1.AddressingModes.Absolute:
      return readNextAddress_1.readNextAddress(vm);

    case types_1.AddressingModes.AbsoluteX:
      return readNextAddress_1.readNextAddress(vm) + vm.registers.X;

    case types_1.AddressingModes.AbsoluteY:
      return readNextAddress_1.readNextAddress(vm) + vm.registers.Y;

    case types_1.AddressingModes.ZeroPage:
      return 0x00 + vm.ram[vm.registers.PC++];

    case types_1.AddressingModes.ZeroPageX:
      return 0x00 + vm.registers.X;

    case types_1.AddressingModes.ZeroPageY:
      return 0x00 + vm.registers.Y;

    case types_1.AddressingModes.Indirect:
      return bytes_1.bytesToAddress(__spread(vm.ram.slice(readNextAddress_1.readNextAddress(vm), 2)));

    default:
      throw new Error("Instruction or addressing mode not found: " + ins.mnemonic + " " + ins.addressingMode);
  }
};

exports.step = function (vm) {
  var inst = instructions_1.instructions()[vm.ram[vm.registers.PC++]];
  inst.handler(vm, exports.getArgumentForAddressingMode(vm, inst));
  vm.onVmUpdate(__assign({}, vm));
};