"use strict";

exports.__esModule = true;

var types_1 = require("../types");

var handler = function handler(register) {
  return function (vm, addr) {
    return vm.ram[addr] = vm.registers[register];
  };
};

exports.store = function () {
  return {
    0x8d: {
      mnemonic: "STA",
      handler: handler("A"),
      addressingMode: types_1.AddressingModes.Absolute
    },
    0x9d: {
      mnemonic: "STA",
      handler: handler("A"),
      addressingMode: types_1.AddressingModes.AbsoluteX
    },
    0x99: {
      mnemonic: "STA",
      handler: handler("A"),
      addressingMode: types_1.AddressingModes.AbsoluteY
    },
    0x85: {
      mnemonic: "STA",
      handler: handler("A"),
      addressingMode: types_1.AddressingModes.ZeroPage
    },
    0x95: {
      mnemonic: "STA",
      handler: handler("A"),
      addressingMode: types_1.AddressingModes.ZeroPageX
    },
    0x81: {
      mnemonic: "STA",
      handler: handler("A"),
      addressingMode: types_1.AddressingModes.IndexedIndirect
    },
    0x91: {
      mnemonic: "STA",
      handler: handler("A"),
      addressingMode: types_1.AddressingModes.IndirectIndexed
    },
    0x8e: {
      mnemonic: "STX",
      handler: handler("X"),
      addressingMode: types_1.AddressingModes.Absolute
    },
    0x86: {
      mnemonic: "STX",
      handler: handler("X"),
      addressingMode: types_1.AddressingModes.ZeroPage
    },
    0x96: {
      mnemonic: "STX",
      handler: handler("X"),
      addressingMode: types_1.AddressingModes.ZeroPageY
    },
    0x8c: {
      mnemonic: "STY",
      handler: handler("Y"),
      addressingMode: types_1.AddressingModes.Absolute
    },
    0x84: {
      mnemonic: "STY",
      handler: handler("Y"),
      addressingMode: types_1.AddressingModes.ZeroPage
    },
    0x94: {
      mnemonic: "STY",
      handler: handler("Y"),
      addressingMode: types_1.AddressingModes.ZeroPageX
    }
  };
};