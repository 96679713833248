"use strict";

exports.__esModule = true;

var bytes_1 = require("./bytes");

exports.readNextAddress = function (vm) {
  var byte1 = vm.ram[vm.registers.PC++];
  var byte2 = vm.ram[vm.registers.PC++];
  return bytes_1.bytesToAddress([byte1, byte2]);
};